<template>
  <dx-util-popup
    ref="itemCostUpdaterPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="true"
    :position="'center'"
    @shown="itemCostUpdaterPopupShown"
    @hidden="itemCostUpdaterPopupHidden"
  >
    <div>
      <dx-data-grid
        v-if="componentId"
        id="itemCostsGrid"
        ref="itemCostsGridRef"
        :data-source="ItemCostDataSource"
        :height="setHeight"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :hover-state-enabled="true"
        :row-alternation-enabled="false"
        :customize-columns="customizeColumns"
        @option-changed="onOptionChanged"
        @initialized="onInitialized"
        @content-ready="onContentReady"
        @toolbar-preparing="onToolbarPreparing"
      >
        <dx-sorting mode="single" />
        <dx-remote-operations :paging="true" :sorting="true" :filtering="false" />
        <dx-column data-field="title" cell-template="titleCellTemplate" :min-width="300" />
        <template #titleCellTemplate="{data}">
          <div class="dx-product-title">
            {{ data.value }}
          </div>
        </template>
        <!-- <dx-column data-field="asin" caption="ASIN" :width="120" cell-template="asinCellTemplate" /> -->
        <template #asinCellTemplate="{data}">
          <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
            {{ data.value }}
          </a>
        </template>
        <dx-column data-field="sku" width="auto" caption="SKU" />
        <dx-column data-field="quantity" caption="QTY" :width="80" />
        <dx-column data-field="sale" caption="Total Sales" :format="'$ #,##0.##'" :width="300" />
        <!-- <dx-column data-field="planId" /> -->
        <template #filterToolbar></template>
        <dx-column data-field="Item Update Info" cell-template="editCostTemplate" :width="300" />
        <template #editCostTemplate="{data}">
          <edit-cost :row="data" @emit-cost-updated="onCostUpdated" @emit-cost-entered="addCostUpdateList" />
        </template>
        <dx-paging :page-size="pagerOptions.pageSize" />
        <dx-pager
          :allowed-page-sizes="pagerOptions.allowedPageSizes"
          :show-info="pagerOptions.showInfo"
          :show-navigation-buttons="pagerOptions.showNavigationButtons"
          :show-page-size-selector="pagerOptions.showPageSizeSelector"
          :visible="pagerOptions.visible"
        />
      </dx-data-grid>
    </div>
    <!-- <div class="text-right">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Select" type="success" style="width:100px" @click="select" />
    </div> -->
  </dx-util-popup>
</template>

<script>
import { Notify } from '@/@robustshell/utils'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import amazonReportService from '@/http/requests/reports/amazonReportService.js'
import { ItemCostDataSource } from './itemCostUpdateStore.js'

export default {
  components: {
    'edit-cost': () => import('./EditCost.vue'),
  },
  mixins: [GridBase],
  props: {
    componentId: {
      type: String,
      default: '',
    },
    monthId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ItemCostDataSource,
      popupTitle: '',
      selected: {},
      itemCosts: [],
      query: '',
      selectedProductId: null,
      isVisible: false,
      itemCostList: {},
    }
  },
  computed: {
    itemCostUpdaterPopup() {
      return this.$refs.itemCostUpdaterPopupRef.instance
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  watch: {
    componentId: {
      immediate: false,
      handler() {
        this.itemCostUpdaterPopup.show()
        ItemCostDataSource.searchValue({ monthId: this.monthId })
        ItemCostDataSource.reload()
      },
    },
  },
  methods: {
    // onEditorPreparing(e) {
    //   if (e.parentType === 'searchPanel') {
    //     e.editorOptions.onValueChanged = arg => {
    //       const query = arg.component.option('value')
    //       if (!query) {
    //         this.query = query.trim()
    //         e.component.searchByText('')
    //         this.searchByFilter()
    //       }
    //     }
    //     e.editorOptions.onKeyDown = arg => {
    //       if (arg.event.keyCode === 13) {
    //         let query = arg.component.option('value')
    //         query = query.trim()
    //         arg.component.option('value', query)
    //         this.query = query
    //         this.searchByFilter()
    //         e.component.searchByText(query)
    //       }
    //       if (arg.event.keyCode === 27) {
    //         e.component.searchByText('')
    //         arg.component.option('value', '')
    //         this.query = ''
    //         this.searchByFilter()
    //       }
    //     }
    //   }
    // },
    openProductDetails(e) {
      this.selectedProductId = e.id
      this.isVisible = true
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageIndex') {
        this.pageIndex = e.value
      }
      this.pageSize = this.refs.itemCostsGridRef.instance.pageSize()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'save',
          text: 'Update All',
          type: 'success',
          onClick: () => {
            const itemsArray = Object.values(this.itemCostList)
            amazonReportService.updateItemCost(itemsArray).then(() => {
              Notify.success('Costs are successfully updated.')
              this.itemCostList = []
              ItemCostDataSource.reload()
            })
          },
        },
        location: 'after',
      })
    },
    searchByFilter() {},
    itemCostUpdaterPopupShown() {
      this.popupTitle = 'Item Cost Updater'
      this.selected = {}
    },
    itemCostUpdaterPopupHidden() {
      this.itemCostList = {}
      this.$emit('on-cost-updater-closed')
    },
    closePopup() {
      this.itemCostUpdaterPopup.hide()
    },
    select() {
      if (!this.selected?.id) {
        Notify.warning('Please select an item')
        return
      }
      this.$emit('on-emit-item-selected', this.selected)
      this.closePopup()
    },
    onCostUpdated() {
      ItemCostDataSource.reload()
    },
    addCostUpdateList(payload) {
      if (payload.cost !== null && payload.cost > 0) {
        this.itemCostList[payload.itemId] = payload
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
