import { render, staticRenderFns } from "./ItemCostUpdater.vue?vue&type=template&id=446ca4c4&scoped=true"
import script from "./ItemCostUpdater.vue?vue&type=script&lang=js"
export * from "./ItemCostUpdater.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446ca4c4",
  null
  
)

export default component.exports